<template>
    <section class="tables">
        <div class="page-header">
            <h3 class="page-title">
                {{ info.pageName }}s
            </h3>
            <Search :info="info" />

            <div class="btn-group" role="group" aria-label="Basic example">

                <!-- <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search ebooks"
                    v-model="keyword"></b-form-input>
                <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
                </b-button>
                <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
                </b-button> -->

                <button @click="openInsertPage()" type="button" class="btn btn-success believe-btn">
                    <i class="fa fa-plus"></i> Add {{ info.pageName }} </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">

                        <!-- show data component  -->
                        <DynamicTable :info="info" />
                        <!-- :data="tableData" -->

                        <!-- pagination component -->
                        <Pagination :info="info" />

                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </section>
</template>
<script>
import DynamicTable from '../DynamicTable.vue';
import Pagination from '../CustomPagination.vue';
import Search from '../CustomSearch.vue';
//   import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
//   import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";

export default {
    name: "ListingComponent",
    components: {
        DynamicTable, Pagination, Search
        //   AudioEditModal,
        //   AudioAddModal,
    },
    props: {
        info: {
            required: true,
            type: Object
        }
    },
    data: function () {
        return {
            page: 1,
            tableData: []
        };
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            const params = {
                url: this.info?.endpoint,
                keys: this.info?.keysToDisplay,
            }
            this.$store.dispatch('fetchData', params);
        },

        openInsertPage() {
            this.$router.push({ path: this.info?.addPageUrl });
        },
    },



}
</script>
    
<style></style>
    