<template>
    <div class="btn-group">
        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder='Search...'
            v-model="keyword"></b-form-input>
        <b-button @click="fetchData()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>
    </div>
</template>
  
<script>
export default {
    name: "CustomPagination",
    data(){
        return{
            keyword : "",
        }
    },
    props: {
        info: {
            required: true,
            type: Object
        }
    },
    computed: {
        getContentData() {
            return this.$store.getters.getContentData;
        },
        getPagiData() {
            return this.$store.getters.getPagiData;
        }
    },
    methods: {

        clearSearchInput() {
            this.keyword = "";
            const params = {
                url: this.info?.endpoint,
                keys: this.info?.keysToDisplay,
            }
            this.$store.dispatch('fetchData', params);
        },

        handleKeyPress(event) {
            if (event.key == "Enter" && this.keyword != "") {
                this.fetchData()
            }
        },

        fetchData() {
            const params = {
                url: this.info?.endpointSearch,
                keys: this.info?.keysToDisplay,
                keyword : this.keyword,
            }
            this.$store.dispatch('fetchSearchedData', params);
        }
    }
};
</script>
