<template>
    <ListingComponent :info="info"/>
</template>
<script>
import ListingComponent from './ListingPage/index.vue';

export default {
    name : "GoalCustom",
    components: {
        ListingComponent
    },
    data: function () {
        return {
            info: {
                pageName: "Goal",
                pageUrl: "/customGoals",
                endpoint: '/admin/all-goals',
                endpointSearch: 'admin/livesearch-gaols',
                keysToDisplay: ['id', 'name', 'status'],
                endpointStatus: '/admin/goal-status',
                addPageUrl: '/customGoals/add',
                addPageEndpoint: '/admin/create-goal',
                formDataInsert: [
                    { 
                        objKey : 'name',
                        label: 'Goals', 
                        name: 'goals', 
                        value: '' , 
                        placeholder : 'Enter comma seperated goals here',
                        splitColumnData : true,
                        type : 'input'
                    },
                ],
            }
        };
    },
    mounted() {
        this.$store.commit('setInfo',this.info);
    }
}
</script>
