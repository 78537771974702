<template>
    <table class="table table-hover table-striped">
        <thead>
            <tr>
                <th>#</th>
                <th v-for="(value, key) in content[0]" :key="key">
                    <span v-if="key != 'id'">{{ key | capitalize }}</span>
                </th>
                <th class="text-center">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, key) in content" :key="item.id">
                <td>{{ ++key }}</td>
                <td v-for="(value, key) in item" :key="key">
                    <span v-if="key != 'id'">

                        <span v-if="key != 'status' &&
                            key != 'is_paid' &&
                            key != 'image' &&
                            key != 'url'
                            ">
                            {{ value }}
                        </span>

                        <span v-if="key == 'image'">
                            <img :src="item.image" alt="" />
                        </span>

                        <span v-if="key == 'url'">
                            <a v-if="item.url != null" :href="item.url" target="blank">View Ebook</a>
                        </span>

                        <span v-if="key == 'status'">
                            <toggle-button @change="changeStatus(item.id)"
                                :value="(item.status == 'Active') ? true : false" />
                        </span>

                        <span v-if="key == 'is_paid'">
                            <toggle-button @change="changeSubscriptionStatus(item)"
                                :value="(item.is_paid == 1) ? true : false" />
                        </span>
                    </span>
                </td>
                <td class="text-center">
                    <button class="btn btn-warning">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger">
                        <i class="fa fa-trash-o"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
  
<script>
import { mapState } from 'vuex';
export default {
    props: {
        info: {
            required: true,
            type: Object
        }
    },

    computed: {
        successMessage() {
            console.log('computed : ', this.$store.state.successMessage)
            return this.$store.state.successMessage;
        },
        ...mapState(['content'])
    },

    watch: {
        successMessage(newMessage) {
            if (newMessage) {
                console.log('watch : ', newMessage)
                this.$swal('Success!', newMessage, 'success')
                setTimeout(() => {
                    this.clearSuccessMessage();
                }, 1000);
            }
        },
    },

    methods: {
        editRow(item) {
            // Implement edit functionality here
            console.log('Editing row:', item);
        },
        deleteRow(id) {
            // Implement delete functionality here
            console.log('Deleting row with ID:', id);
        },
        changeStatus(id) {
            const params = {
                url: this.info?.endpointStatus,
                id: id
            }
            this.$store.dispatch('changeStatus', params);
        },
        changeSubscriptionStatus(item) {
            const params = {
                url: this.info?.endpointSubscriptionStatus,
                type: this.info?.pageName,
                id: item.id
            }
            this.$store.dispatch('changeSubscriptionStatus', params);
        },
        clearSuccessMessage() {
            this.$store.commit('clearSuccessMessage');
        },
    }
};
</script>
