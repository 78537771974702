<template>
    <!-- v-if="!searchButtonClicked" -->
    <div v-if="!getSearchedData.contentSearched" class="row">
        <div class="col-md-6 pageInfoBox">
            Total records : {{ getPagiData.total }}
            | Total pages : {{ getPagiData.totalPages }}
            | Current page : {{ getPagiData.page }}
        </div>
        <div class="col-md-6 text-right">
            <button class="btn btn-success" :disabled="getPagiData.backButton" @click="fetchData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
            <button class="btn btn-success" :disabled="getPagiData.nextButton" @click="fetchData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "CustomPagination",
    // data(){
    //     return{
    //     }
    // },
    props: {
        info: {
            required: true,
            type: Object
        }
    },
    computed: {
        getContentData() {
            return this.$store.getters.getContentData;
        },
        getPagiData(){
            return this.$store.getters.getPagiData;
        },
        getSearchedData(){
            return this.$store.getters.getSearchedData;
        }
    },
    methods: {
        fetchData(type) {
            const params = {
                url: this.info?.endpoint,
                keys: this.info?.keysToDisplay,
            }
            if(type == "next"){
                this.$store.commit('increment');
            }else{
                this.$store.commit('decrement');
            }
            this.$store.dispatch('fetchData', params);
            // console.log('abcefg ::: ' , this.getContentData);
        }
    }
};
</script>
